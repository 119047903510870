import { Injectable } from '@angular/core';
import { GraphService } from 'src/app/kernel/graphql/services/graph.service';
import { GqlResponseInterface } from 'src/app/kernel/graphql/interfaces/gql-response.interface';
import { GraphqlBaseInput } from 'src/app/kernel/graphql/models/graphql-base.input';
import {
  ICancelSubscriptionDtoInput,
  ICancelSubscriptionResult,
  ISubscribeDtoInput,
  ISubscribeResult,
} from '../interfaces/subscription.interfaces';
import {
  CANCEL_SUBSCRIPTION_RESULT_DATA_SHAPE,
  SUBSCRIBE_BASE_DATA_SHAPE,
  SUBSCRIBE_RESULT_DATA_SHAPE,
  SUBSCRIPTION_BASE_DATA_SHAPE,
} from '../datashape/subscription.datashape';
import { BehaviorSubject, first, map, of, switchMap, tap } from 'rxjs';
import { EPaymentIntentStatus } from 'src/app/chatperk-core/enums/EPaymentIntentStatus';
import { ISubscription } from 'src/app/chatperk-core/interfaces';
import { PaymentService } from 'src/app/shared/subscription-shared/services/payment.service';
import { EFeatureKey } from 'src/app/chatperk-core/enums/EFeatureKey';

@Injectable({ providedIn: 'root' })
export class SubscriptionService {
  private subscription$ = new BehaviorSubject<ISubscription | null>(null);

  constructor(
    private graphService: GraphService,
    private paymentService: PaymentService
  ) {}

  queryActiveSubscription(dataShape: string = SUBSCRIPTION_BASE_DATA_SHAPE) {
    return this.graphService.constructQuery<
      GqlResponseInterface<ISubscription>
    >([dataShape], 'myCurrentSubscription');
  }

  loadActiveSubscription() {
    return this.queryActiveSubscription().pipe(
      first(),
      tap((value) => {
        console.log(value);
        this.setActiveSubscription(value.data?.['myCurrentSubscription']);
      })
    );
  }

  setActiveSubscription(subscription: ISubscription) {
    this.subscription$.next(subscription);
  }

  getActiveSubscription(): ISubscription | null {
    return this.subscription$.value ?? null;
  }

  observeActiveSubscription() {
    return this.subscription$.asObservable();
  }

  getActiveSubscriptionFeature(key: EFeatureKey) {
    return this.getActiveSubscription()?.aggregatedFeatures.find(
      (f) => f.key === key
    );
  }

  createSubscription(
    input: ISubscribeDtoInput,
    dataShape: string = SUBSCRIBE_RESULT_DATA_SHAPE
  ) {
    const gqlInput = new GraphqlBaseInput();
    gqlInput.addParams({ input }, { input: 'SubscribeInput!' });

    return this.graphService
      .constructMutation<GqlResponseInterface<ISubscribeResult>>(
        'createSubscription',
        gqlInput.__paramsDef,
        gqlInput,
        [dataShape]
      )
      .pipe(
        switchMap((res) => this.loadActiveSubscription().pipe(map(() => res)))
      );
  }

  syncMySubscription(dataShape: string = SUBSCRIBE_BASE_DATA_SHAPE) {
    return this.graphService
      .constructMutation<GqlResponseInterface<ISubscription>>(
        'syncMySubscription',
        {},
        {},
        [dataShape]
      )
      .pipe(
        switchMap((res) => this.loadActiveSubscription().pipe(map(() => res)))
      );
  }

  updateSubscription(
    input: ISubscribeDtoInput,
    dataShape: string = SUBSCRIBE_RESULT_DATA_SHAPE
  ) {
    const gqlInput = new GraphqlBaseInput();
    gqlInput.addParams({ input }, { input: 'SubscribeInput!' });

    return this.graphService
      .constructMutation<GqlResponseInterface<ISubscribeResult>>(
        'updateSubscription',
        gqlInput.__paramsDef,
        gqlInput,
        [dataShape]
      )
      .pipe(
        switchMap((res) => this.loadActiveSubscription().pipe(map(() => res)))
      );
  }

  createAndConfirmSubscription(
    input: ISubscribeDtoInput,
    dataShape: string = SUBSCRIBE_RESULT_DATA_SHAPE
  ) {
    return this.createSubscription(input, dataShape).pipe(
      switchMap((result) => {
        const subscription = result.data?.['createSubscription']?.subscription;
        if (subscription) {
          return this.confirmSubscription(subscription);
        }
        return of(null);
      })
    );
  }

  updateAndConfirmSubscription(
    input: ISubscribeDtoInput,
    dataShape: string = SUBSCRIBE_RESULT_DATA_SHAPE
  ) {
    return this.updateSubscription(input, dataShape).pipe(
      switchMap((result) => {
        const subscription = result.data?.['updateSubscription']?.subscription;
        if (subscription) {
          return this.confirmSubscription(subscription);
        }
        return of(null);
      })
    );
  }

  cancelSubscription(
    input: ICancelSubscriptionDtoInput,
    dataShape: string = CANCEL_SUBSCRIPTION_RESULT_DATA_SHAPE
  ) {
    const gqlInput = new GraphqlBaseInput();
    gqlInput.addParams({ input }, { input: 'CancelSubscriptionInput!' });

    return this.graphService
      .constructMutation<GqlResponseInterface<ICancelSubscriptionResult>>(
        'cancelSubscription',
        gqlInput.__paramsDef,
        gqlInput,
        [dataShape]
      )
      .pipe(
        switchMap((res) => this.loadActiveSubscription().pipe(map(() => res)))
      );
  }

  confirmSubscription(subscription: ISubscription) {
    const paymentIntent = subscription.lastInvoice.paymentIntent;

    if (
      paymentIntent?.status &&
      [
        EPaymentIntentStatus.RequiresAction,
        EPaymentIntentStatus.RequiresConfirmation,
        EPaymentIntentStatus.RequiresPaymentMethod,
      ].includes(paymentIntent.status)
    ) {
      return this.paymentService
        .confirmPayment(
          paymentIntent,
          subscription!.lastInvoice.id,
          paymentIntent.status === EPaymentIntentStatus.RequiresConfirmation
        )
        .pipe(
          tap((result) => {
            if (result) {
              subscription!.lastInvoice.paymentIntent = result.paymentIntent;
            }
          }),
          map((_) => subscription)
        );
    }

    return of(subscription);
  }
}
