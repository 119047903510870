import {Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit, OnChanges {
  @Input() mode: 'indeterminate' | 'determinate' = 'indeterminate';
  @Input() total: number = 100;
  @Input() completed: number = 0;
  @Input() thin: boolean = false;
  @Input() onlyIncrease: boolean = false;

  progress: number = 0;

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['total'] || changes['completed']) {
      this.progress = this.calculateProgress();
      console.log('updating', this.total, this.completed, this.progress)
    }
  }

  calculateProgress(): number {
    if (this.total <= 0 || this.completed <= 0) {
      return 0;
    }
    if (!this.onlyIncrease) {
      return this.completed / this.total * 100
    }
    return Math.max(Math.min((this.completed / this.total) * 100, 100), this.progress);
  }
}
