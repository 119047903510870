<p-sidebar
  [visible]="visible"
  (visibleChange)="handleVisibleChange($event)"
  [styleClass]="theme"
  [position]="position"
  [modal]="true"
  (onShow)="onShow($event)"
  (onHide)="onHide($event)"
  [dismissible]="dismissible"
  [closeOnEscape]="closeOnEscape"
  [showCloseIcon]="showCloseIcon"
  [class]="'sidebar ' + theme"
  [autoZIndex]="autoZIndex"
  [baseZIndex]="baseZIndex"
>
  <div class="side-dialog-content"
       *ngIf="headerTemplateRef || contentTemplateRef || footerTemplateRef || cardsTemplateRef"
  >
    <ng-container *ngTemplateOutlet="headerTemplateRef"></ng-container>

    <ng-container [ngTemplateOutlet]="actionsTemplateRef" *ngIf="actionsTemplateRef" />

    <ng-container *ngTemplateOutlet="contentTemplateRef"></ng-container>

    <ng-container *ngTemplateOutlet="footerTemplateRef"></ng-container>
  </div>

  <div class="sidebar__content" *ngIf="!headerTemplateRef && !contentTemplateRef && !footerTemplateRef && !cardsTemplateRef">
    <button
      pButton
      pRipple
      type="button"
      icon="pi pi-times"
      class="p-button-rounded sidebar__close"
      (click)="hideSidebar()"
      *ngIf="!showCloseIcon"
    ></button>
    <ng-content></ng-content>
  </div>
</p-sidebar>
