import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Observable, Subject, takeUntil, takeWhile } from 'rxjs';
import {
  IBillingAccount,
  IPaymentMethod,
  IPaymentMethodBillingInfo,
} from 'src/app/chatperk-core/interfaces';
import { IAfterSubmissionEmitEvent } from 'src/app/shared/payment-method/interfaces/payment-method.interfaces';
import { CustomInput } from 'src/app/kernel/form/classes/custom-input';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-select-payment-method-popup',
  templateUrl: './select-payment-method-popup.component.html',
  styleUrls: ['./select-payment-method-popup.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectPaymentMethodPopupComponent),
      multi: true,
    },
  ],
})
export class SelectPaymentMethodPopupComponent
  extends CustomInput<IPaymentMethod>
  implements OnInit
{
  private destroy$ = new Subject<void>();

  @Input() visible = false;
  @Input() submitDisabled = false;
  @Input() submitLabel = '';
  @Input() paymentMethods: IPaymentMethod[] = [];
  @Input() setupClientSecretObservable?: Observable<string | undefined>;
  @Input() setupClientSecret?: string;
  @Input() addPaymentMethodView = false;
  @Input() defaultBillingInfo?: Partial<IPaymentMethodBillingInfo>;
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() onAddPaymentMethod = new EventEmitter<IAfterSubmissionEmitEvent>();
  @Output() onSubmit = new EventEmitter<IPaymentMethod>();
  @Output() addPaymentMethodViewChange = new EventEmitter<boolean>();
  @Output() setupClientSecretChange = new EventEmitter<string | undefined>();

  constructor(private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    if (
      this.route.snapshot.queryParamMap.has('redirect_status') &&
      this.route.snapshot.queryParamMap.has('setup_intent_client_secret')
    ) {
      this.handleVisibleChange(true);
      this.toggleAddPaymentMethodView(true);
    }
  }

  toggleAddPaymentMethodView(isOpen = !this.addPaymentMethodView) {
    if (isOpen && !this.setupClientSecret) {
      this.setupClientSecretObservable!.pipe(
        takeUntil(this.destroy$)
      )!.subscribe((secret) => {
        this.setupClientSecret = secret;
        this.addPaymentMethodView = isOpen;
        this.addPaymentMethodViewChange.emit(this.addPaymentMethodView);
        this.setupClientSecretChange.emit(this.setupClientSecret);
      });
    } else {
      this.addPaymentMethodView = isOpen;
      this.addPaymentMethodViewChange.emit(this.addPaymentMethodView);
    }
  }

  handleSavePaymentMethod(res: IAfterSubmissionEmitEvent) {
    this.onAddPaymentMethod.emit(res);
  }

  handleVisibleChange(value: boolean) {
    this.visible = value;
    this.visibleChange.emit(value);
  }

  submit(value: IPaymentMethod) {
    this.onSubmit.emit(value);
  }

  override ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    super.ngOnDestroy();
  }
}
