import { Injectable } from '@angular/core';
import { GraphService } from 'src/app/kernel/graphql/services/graph.service';
import { first, map } from 'rxjs';
import { GqlResponseInterface } from 'src/app/kernel/graphql/interfaces/gql-response.interface';
import {
  IExternalGatewayRef,
  IPaymentMethod,
} from 'src/app/chatperk-core/interfaces';
import { PAYMENT_METHOD_FULL_DATA_SHAPE } from 'src/app/chatperk-core/data-shape/payment-method.datashape';
import {
  IAddPaymentMethodDto,
  IUpdatePaymentMethodDto,
} from '../../../../../shared/payment-method/interfaces/payment-method.interfaces';

@Injectable()
export class PaymentMethodsService {
  constructor(private graphService: GraphService) {}

  initiateSetup() {
    return this.graphService
      .constructMutation<{
        initiatePaymentMethodSetup: { clientSecret: string };
      }>('initiatePaymentMethodSetup', {}, {}, ['clientSecret'])
      .pipe(
        first(),
        map((res) => ({
          ...res,
          data: res.data?.initiatePaymentMethodSetup.clientSecret,
        }))
      );
  }

  list(dataShape: string = PAYMENT_METHOD_FULL_DATA_SHAPE) {
    return this.graphService
      .constructListingQuery<{
        listingPaymentMethods: { data: IPaymentMethod[] };
      }>([dataShape], 'listingPaymentMethods')
      .pipe(
        first(),
        map((res) => ({ ...res, data: res.data.listingPaymentMethods?.data }))
      );
  }

  getAll(dataShape: string = PAYMENT_METHOD_FULL_DATA_SHAPE) {
    return this.graphService.constructQuery<
      GqlResponseInterface<IPaymentMethod[]>
    >([dataShape], 'allPaymentMethods');
  }

  add(input: IAddPaymentMethodDto) {
    return this.graphService
      .constructMutation(
        'addPaymentMethod',
        { input: 'AddPaymentMethodInput' },
        { input },
        ['id']
      )
      .pipe(first());
  }

  update(id: string, input: IUpdatePaymentMethodDto) {
    return this.graphService
      .constructMutation(
        'updatePaymentMethod',
        { id: 'ID!', input: 'UpdatePaymentMethodInput' },
        { id, input },
        ['id']
      )
      .pipe(first());
  }

  delete(id: string) {
    return this.graphService
      .constructMutation('deletePaymentMethod', { id: 'ID!' }, { id }, [
        'text',
        'code',
      ])
      .pipe(first());
  }

  getById(id: string, dataShape: string = PAYMENT_METHOD_FULL_DATA_SHAPE) {
    return this.graphService.constructQuery<
      GqlResponseInterface<IPaymentMethod>
    >([dataShape], 'paymentMethod', { id });
  }
}
