<sxw-side-dialog
  [backOnClose]="false"
  [visible]="visible"
  (visibleChange)="handleVisibleChange($event)"
  (onHideDialog)="toggleAddPaymentMethodView(false)"
  theme="sxw-popup-cards"
>
  <h2>
    {{
      (addPaymentMethodView
        ? "paymentMethod.addTitle"
        : "paymentMethod.selectTitle"
      ) | translate
    }}
  </h2>

  <app-payment-methods-list
    *ngIf="!addPaymentMethodView"
    [ngModel]="value"
    (ngModelChange)="writeValue($event); onChange($event)"
    [paymentMethods]="paymentMethods"
  ></app-payment-methods-list>

  <app-add-payment-method-form
    *ngIf="addPaymentMethodView && setupClientSecret"
    [defaultBillingInfo]="defaultBillingInfo"
    [clientSecret]="setupClientSecret"
    (afterSubmitSuccess)="handleSavePaymentMethod($event)"
  ></app-add-payment-method-form>

  <button
    *ngIf="!addPaymentMethodView"
    class="subscribe-button"
    (click)="submit(value!)"
    [disabled]="!value || submitDisabled"
  >
    {{ submitLabel | translate }}
  </button>

  <a
    *ngIf="setupClientSecretObservable"
    class="link add-payment-link"
    (click)="toggleAddPaymentMethodView()"
  >
    {{
      (addPaymentMethodView
        ? "paymentMethod.buttons.backToPaymentMethodsList"
        : "paymentMethod.buttons.addAnotherPaymentMethod"
      ) | translate
    }}
  </a>
</sxw-side-dialog>
